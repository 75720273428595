.space-span{
  background-color: transparent;
  color: #FFFFFF;
  font-weight: bold;
  padding: 0 0.15rem;
  line-height: 30px;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline-block;
}

.word-span{
  margin :0 0.15rem !important;
}